import React, { useEffect, useState } from "react"
import { withI18n } from "~/hocs"
import { NextPage } from "next"
import { AppCommonProps } from "types/app"
import { Modal } from "react-bootstrap"
import moment from "moment-timezone"
import { createStructuredSelector } from "reselect"
import {
  makeSelectIsAuthenticated,
  makeSelectProfile,
} from "~/store/auth/selector"
import { connect } from "react-redux"

const FirstUserLoginModal: NextPage<AppCommonProps> = ({
  onHide,
  isAuthen,
  profile,
}) => {
  const [htmlContent, setHtmlContent] = useState<string>("") // State lưu HTML từ API
  const [isLoading, setIsLoading] = useState<boolean>(false) // State hiển thị trạng thái tải dữ liệu
  const [show, setShow] = useState<boolean>(false)
  const now = moment().tz("Asia/Tokyo")
  const startDate = moment.tz("2025-04-01 00:00:00", "Asia/Tokyo")
  const endDate = moment.tz("2025-04-13 00:00:00", "Asia/Tokyo")
  const endDate2 = moment.tz("2025-05-01 00:00:00", "Asia/Tokyo")

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const isWindows = () => {
    return navigator.platform.indexOf("Win") > -1
  }
  const [newParam, setNewParam] = useState(0)

  useEffect(() => {
    if (isAuthen && profile?.session_id && now.isBetween(startDate, endDate2)) {
      const storedData = localStorage.getItem("user_logined_array");
      const currentDate = moment().tz("Asia/Tokyo").format("YYYY-MM-DD");
      const userID = profile?.id;
  
      if (!storedData) {
        const newUserObject = {
          id: userID,
          date: currentDate,
          is_Stopped_1: false,
          is_Stopped_2: false,
        };
        localStorage.setItem("user_logined_array", JSON.stringify([newUserObject]));
        handleShowModalUserLoginFirst();
      } else {
        try {
          const parsedArray = JSON.parse(storedData);
  
          if (Array.isArray(parsedArray)) {
            const userObject = parsedArray.find((obj) => obj.id === userID);
  
            if (!userObject) {
              const newUserObject = {
                id: userID,
                date: currentDate,
                is_Stopped_1: false,
                is_Stopped_2: false,
              };
              parsedArray.push(newUserObject);
              localStorage.setItem("user_logined_array", JSON.stringify(parsedArray));
              handleShowModalUserLoginFirst();
            } else {
              if (userObject.date !== currentDate) {
                if (!userObject.is_Stopped_1 || !userObject.is_Stopped_2) {
                  userObject.date = currentDate;
                  localStorage.setItem("user_logined_array", JSON.stringify(parsedArray));
                  handleShowModalUserLoginFirst();
                }
              }
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  }, []);
  

  const handleShowModalUserLoginFirst = async () => {
    setIsLoading(true);
    try {
      const storedData = localStorage.getItem("user_logined_array");
      const parsedArray = storedData ? JSON.parse(storedData) : [];
      const userObject = parsedArray.find((obj) => obj.id === profile?.id);
  
      let numberModal = now.isBetween(startDate, endDate) ? (Math.random() < 0.5 ? 1 : 2) : 2
  
      if (userObject) {
        if (userObject.is_Stopped_1 && userObject.is_Stopped_2) {
          setIsLoading(false);
          return; // Nếu cả 2 modal đều bị tắt, không hiện modal nào
        } else if (userObject.is_Stopped_1) {
          numberModal = 2
        } else if (userObject.is_Stopped_2) {
          numberModal = 1
        }
      }
  
      const response = await fetch(
        `${process.env.SSO_SERVER_URL}/get-banner-dac-pr?new=${numberModal}`
      );
      setNewParam(numberModal)
      if (!response.ok) {
        handleClose();
        return;
      }
  
      const html = await response.text();
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
  
      if (isWindows()) {
        const box = doc.querySelector(".pr-designac-modal__box");
        box?.classList.add("window-screen");
      }
  
      const styleTags = Array.from(doc.querySelectorAll("style"));
      styleTags.forEach((styleTag) => {
        document.head.appendChild(styleTag.cloneNode(true));
      });
  
      const updatedHTML = doc.body.innerHTML;
      setHtmlContent(updatedHTML);
      handleShow();
    } catch (error) {
      console.error("Error fetching HTML content:", error);
      handleClose();
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleCheckboxClick = (event: Event) => {
    const target = event.target as HTMLInputElement
    if (target.id === "checkbox-hidden-dac-pr" && target.type === "checkbox") {
      const storedData = localStorage.getItem("user_logined_array")
      if (storedData) {
        try {
          const parsedArray = JSON.parse(storedData)
          const userObjectIndex = parsedArray.findIndex(
            (obj) => obj.id === profile?.id,
          )
          if (userObjectIndex !== -1) {
            if (newParam == 1) {
              parsedArray[userObjectIndex].is_Stopped_1 = event.target.checked
            } else {
              parsedArray[userObjectIndex].is_Stopped_2 = event.target.checked
            }
            localStorage.setItem(
              "user_logined_array",
              JSON.stringify(parsedArray),
            )
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
  }
  

  useEffect(() => {
    if (show && htmlContent) {
      const modalContent = document.querySelector(".modal-content")

      if (modalContent) {
        modalContent.addEventListener("change", handleCheckboxClick)
        modalContent.addEventListener("click", handleContentClick)
      }

      return () => {
        if (modalContent) {
          modalContent.removeEventListener("click", handleContentClick)
          modalContent.removeEventListener("change", handleCheckboxClick)
        }
      }
    }
  }, [show, htmlContent])

  const handleContentClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
  
    // Kiểm tra nếu phần tử hoặc cha của nó có class `.pr-designac-modal-close`
    if (target.closest(".pr-designac-modal-close")) {
      handleClose()
      e.stopPropagation(); // Ngăn sự kiện lan ra ngoài
      return; // Không mở link
    }
  
    // Nếu không phải <a>, <input>, <label> thì mở link mới
    if (!["A", "INPUT", "LABEL"].includes(target.tagName)) {
      if (newParam == 1) {
        window.open(
          "https://www.design-ac.net/templates/search?keyword=%E3%82%B5%E3%83%B3%E3%82%AD%E3%83%A5%E3%83%BC%E3%82%AB%E3%83%BC%E3%83%89&utm_source=ac_sites&utm_medium=referral&utm_campaign=design_ac_quest&utm_id=250307seasonpopup",
          "_blank"
        );
      } else {
        window.open(
          "https://www.design-ac.net/free-template/presentation?page=1&per_page=100&utm_id=250401seasonpopup",
          "_blank"
        );
      }
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-first-user-login"
      >
        {/* <button
          type="button"
          className="close-button"
          onClick={handleClose}
          aria-label="Close"
        >
          &times;
        </button> */}
        <Modal.Body>
          {isLoading ? (
            <></>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  isAuthen: makeSelectIsAuthenticated(),
  profile: makeSelectProfile(),
})

export default connect(mapStateToProps)(withI18n(FirstUserLoginModal))
