import AppContext, { contextValue } from '~/context'
import { Chatbot, Footer, Hero, Navbar } from '~/components/home'
import React, { FC } from 'react'

import { LayoutProps } from '../../types/LayoutProps'
import { SEOHead } from '~/components/common/seo'
import { SocialShare } from '~/components/common'
import { useRouter } from 'next/router'
import { makeSelectIsPremium } from '~/store/auth/selector'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { withI18n } from '~/hocs'

const MainLayout: FC<LayoutProps> = ({
  children,
  banners,
  path,
  headerData,
  categories,
  isPremium
}) => {
  const router = useRouter()
  return (
    <AppContext.Provider value={contextValue}>
      <Navbar headerData={headerData} />
      <Chatbot/>
      {/* <Hero banners={banners} /> */}

      {children}
      <SEOHead path={path} />
      <SocialShare />
      {router.pathname !== '/creatorP/signin' && <Footer/>}
    </AppContext.Provider>
  )
}
const mapStateToProps = createStructuredSelector({
  isPremium: makeSelectIsPremium(),
})
export default connect(mapStateToProps)(withI18n(MainLayout))
